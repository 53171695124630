#root {
  height: 100%;
}

.ant-layout {
  min-height: 100%;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-wrap {
  max-width: 460px;
  width: 100%;
  text-align: center;
}

.login .ant-form-item, .login .ant-col {
  width: 100%;
}

.login .ant-col {
  margin-bottom: 14px;
}

.login .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0;
}

.login .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}

.login__title {
  font-size: 44px;
}

.login__title span {
  color: #1890ff;
}

.ant-btn {
  line-height: 1.3;
}

.ant-select {
  width: 100%;
}

.form-control {
  min-width: 360px;
}

.ant-spin-nested-loading {
  height: 100%;
}